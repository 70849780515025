<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CRow>
            <CCol lg="6">                          
                <CSelect style="margin-bottom: 0px; width: 120px; height: 30px;"
                    :value.sync="period" 
                    :options="periodOptions"
                    @change="changePeriod"
                  />        
              </CCol>
              <CCol lg="6">
                <CButton style="float: right; height: 33px; width: 150px;" color="info" size="sm" @click="storeModal()">Nuevo Viaje</CButton>
              </CCol>
          </CRow>
                  
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CNav variant="tabs" role="tablist">
        <li style="font-size: 12px;" v-for="(school,i) in schools" :key="'schoool-'+i" class="nav-item" :class="tab === school.code ? 'tab_active' : ''"><a href="/#/travels" target="_self" class="nav-link" @click="changeTab(school.code)"><CLink href="javascript:void(0);" :active="tab === school.code"> {{school.code}} </CLink></a></li>
      </CNav>
      <CTabs>
        <CTab role="tabpanel" aria-labelledby="information-tab" v-if="tab === 'information'">
          INFORMACIÓN DEL VIAJE
        </CTab>
        <CTab role="tabpanel" aria-labelledby="form-tab" v-if="tab === 'form'">
          <QuestionsTable
            hover
            striped
            border
            small
            fixed
            caption="Preguntas del Formulario"
            :id="id"
            :travel="travel"
          />
        </CTab>
        <CTab role="tabpanel" aria-labelledby="students-tab" v-if="tab === 'students'">
          <StudentsTable
            hover
            striped
            border
            small
            fixed
            caption="Alumnos"
            :id="id"
            :travel="travel"
          />
        </CTab>
        <CTab role="tabpanel" aria-labelledby="payments-tab" v-if="tab === 'payments'">
          <PaymentsTable
            hover
            striped
            border
            small
            fixed
            caption="Pagos"
            :id="id"
            type="travel"
            :travel="travel"
          />
        </CTab>
      </CTabs>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #link="{item}">
          <td class="w150 text-center">
            <a v-if="item.is_active == 1" :href="item.link" target="_blank">Ver Formulario</a>
            <br v-if="item.is_active == 1" />
            <CButton v-if="item.is_active == 1" style="margin: 5px 0;" color="secondary" size="sm" @click="closeProcess(item)">Cerrar Formulario</CButton>
            <CButton v-if="item.is_active == 0" style="margin: 5px 0;" color="dark" size="sm" @click="openProcess(item)">Abrir Formulario</CButton>
            <br />
          </td>
        </template>
        <template #dashboard="{item}"><td class="w150 text-center"><a :href="'/#/travels/'+item.id+'/show'">Ver Dashboard</a></td></template>
        <template #date="{item}"><td class="w300 text-center">{{item.dates}}</td></template>
        <template #actions="{item}">
          <td class="table_actions w150">
            <CButton color="success" size="sm" @click="duplicateModal(item)"><CIcon name="cil-copy"/></CButton>
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <TravelModal ref="travelModal" @store="storeProcess" @update="updateProcess" @refresh="refreshProcess"></TravelModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import TravelModal from './TravelModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/travels';
import store from '../../store'
import schools from '../../services/schools';

import moment from 'moment';
import moment_timezone from 'moment-timezone'
moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'TravelsTable',
  components: { TravelModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'destination', label: 'Destino'},
          {key: 'date', label: 'Fecha'},
          {key: 'link', label: 'Formulario'},
          {key: 'dashboard', label: 'Dashboard'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      schools: [],
      tab: false,
      period: new moment().format("YYYY"),
      periodOptions: []
    }
  },
  mounted: async function() {
    let start = moment("01-01-2022", "DD-MM-YYYY");
    let date = [];

    let years = Math.abs(start.diff(moment(), 'years'));

    for (let index = 0; index < years; index++) {
      start = start.add(1,"years");
      let index_date = start.format("YYYY")
      date.push({"label": index_date + "-"+ (index_date*1 + 1),"value": index_date})          
    }
    this.periodOptions =  date;

    if(localStorage.getItem("period") > 0){
      this.period = localStorage.getItem("period");
    }
    else{
      localStorage.setItem("period", this.period);
    }
    
    let _this = this;

    this.loading();
    let response = await schools.get();

    if(response.type == "success"){
      this.schools = response.data;

      let school_tab = localStorage.getItem("school_tab");

      if(school_tab == undefined || school_tab == null){
        if(this.tab === false){
          this.tab = this.schools[0].code;
          localStorage.setItem("school_tab", this.tab);
        }
      }
      else{
        this.tab = school_tab;
      }

      if(this.tab === false){
        this.tab = this.schools[0].code;
      }

      this.$emit("trefresh", this.tab, this.period );
    }

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      this.tab = tab;
      localStorage.setItem("school_tab", this.tab);      
      this.$emit("trefresh", tab ,this.period);
    },
    changePeriod () {
      localStorage.setItem("period", this.period);
      let school_tab = localStorage.getItem("school_tab");
      this.$emit("trefresh", school_tab ,this.period);
    },
    storeModal () {
      this.$refs.travelModal.storeModal();
    },
    duplicateModal (item) {
      this.$refs.travelModal.duplicateModal(item);
    },
    updateModal (item) {
      this.$refs.travelModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Viaje", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("trefresh", this.tab ,this.period);
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("trefresh", this.tab ,this.period);
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("trefresh", this.tab ,this.period);
      }

      this.showToast(response.type, response.message);
    },
    async closeProcess(item) {
      let response = await ws.close(item);
      
      if(response.type == "success"){
        this.$emit("trefresh", this.tab ,this.period);
      }

      this.showToast(response.type, response.message);
    },
    async openProcess(item) {
      let response = await ws.open(item);
      
      if(response.type == "success"){
        this.$emit("trefresh", this.tab ,this.period);
      }

      this.showToast(response.type, response.message);
    },
    async refreshProcess(data) {
      this.$emit("trefresh", this.tab ,this.period);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
